.cropper-view-box {
  outline: 2px solid #2B78C6 !important;
  outline-color:rgb(43, 120, 198, 75%) !important;
}

.cropper-dashed {
  opacity: 1 !important;
}

.cropper-line {
  background-color: #2B78C6 !important;
}

.cropper-point.point-n,
.cropper-point.point-e,
.cropper-point.point-s,
.cropper-point.point-w {
  display: none !important;
}

.cropper-point {
  opacity: 1 !important;
  background-color: #fff !important;
  border: 2px solid #2B78C6 !important;
  width: 12px !important;
  height: 12px !important;
}

.point-ne {
  right: -8px !important;
  top: -8px !important;
}

.point-se {
  right: -8px !important;
  bottom: -8px !important;
}

.point-sw {
  left: -8px !important;
  bottom: -8px !important;
}

.point-nw {
  left: -8px !important;
  top: -8px !important;
}

div:has(> div > div.cropper-container)  {
  overflow: visible !important;
}
